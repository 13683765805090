import { render, staticRenderFns } from "./PriceRebateHelp.vue?vue&type=template&id=4f2866d6&scoped=true&"
import script from "./PriceRebateHelp.vue?vue&type=script&lang=js&"
export * from "./PriceRebateHelp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f2866d6",
  null
  
)

export default component.exports