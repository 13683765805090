<template>
  <div>
    <b-modal title="가격설정 상세" size="xl" v-model="modal.detail" ok-title="저장" cancel-title="닫기" @hide="modalHide">
      <b-row class="mt-1">
        <b-col cols="2" v-if="item.no">
          <small>No</small><br/>
          <div class="col-form-label">{{ item.no }}</div>
        </b-col>
        <b-col v-if="item.shop_id">
          <small>Shop</small><br/>
          <b-input :value="`${item.shop_id}. ${(shopMap[item.shop_id] || {}).boutique}`" disabled></b-input>
        </b-col>
        <b-col>
          <small>타이틀</small><br/>
          <b-form-input v-model="item.name" @input="pageDiff"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="3">
          <small>시작시각</small><br/>
          <b-form-input v-model="item.st" @input="pageDiff"></b-form-input>
        </b-col>
        <b-col cols="3">
          <small>종료시각</small><br/>
          <b-form-input v-model="item.ed" @input="pageDiff"></b-form-input>
        </b-col>
        <b-col>
          <small>적용대상</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.spread" @input="pageDiff" :options="[
            {text: 'HUB DB에만', value: 0},
            {text: '발란몰까지', value: 1},
            {text: '외부몰까지(전부)', value: 2}
          ]"></b-form-radio-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="3">
          <small>생성시각</small><br/>
          <span class="col-form-label">{{ item._cdt }} <span v-if="item._cname">({{ item._cname }})</span></span>
        </b-col>
        <b-col cols="3">
          <small>수정시각</small><br/>
          <span class="col-form-label">{{ item._dt }} <span v-if="item._name">({{ item._name }})</span></span>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <small>비고</small><br/>
          <b-textarea v-model="item.desc" @input="pageDiff"></b-textarea>
        </b-col>
      </b-row>

      <div class="clearfix mt-2">
        <div class="pull-right">
          <b-button class="mr-1" variant="success" size="sm" @click="down()">Xlsx Down</b-button>
        </div>
        <b-form inline>
          <b-input-group>
            <b-form-input v-model.number="addRowNum" size="sm" class="w-65px"></b-form-input>
            <b-input-group-append>
              <b-button variant="light" size="sm" @click="addRows(addRowNum)">행 추가</b-button>
              <b-button variant="secondary" size="sm" @click="addRows(10)">+10</b-button>
              <b-button variant="secondary" size="sm" @click="addRows(50)">+50</b-button>
              <b-button variant="secondary" size="sm" @click="addRows(100)">+100</b-button>
              <b-button variant="secondary" size="sm" @click="addRows(1000)">+1000</b-button>
            </b-input-group-append>
          </b-input-group>

          <b-button class="ml-1" variant="warning" size="sm" @click="removeRows('empty')">빈 행 정리</b-button>
          <b-button class="ml-1" variant="danger" size="sm" @click="removeRows('not_done')">완전하지 않은 행 정리</b-button>
          <b-button class="ml-1" variant="info" size="sm" @click="showRows()">행 데이터</b-button>
        </b-form>
      </div>

      <hot-table class="mt-2" ref="ht" :settings="hotSettings"></hot-table>

      <hr/>

      <div class="clearfix">
        <div>
          *지정 소비자가만 변경할 수 있습니다. 지정 소비자가를 변경하면 할인률 보정 로직에서 제외됩니다.<br/>
          <!-- 230707 부터 옵션가가 제거됨에 따라 Size 별 설정도 제거 -->
<!--          * 사이즈는 생략할 수 있습니다. 생략시 전체 사이즈에 적용됩니다. 최저가 옵션 대비 옵션별 가격 차이가 적용됩니다.<br/>
          * 동일한 발란코드로 사이즈가 있는 항목과 없는 항목이 있을 경우 사이즈가 있는 항목이 우선 적용됩니다.<br/>-->
        </div>
        <div class="pull-right">
          <b-badge v-if="!item.init" variant="success" class="alert-warning"><i class="fa fa-exclamation-circle"></i> 아직 최초로 저장되지 않았습니다</b-badge>
          <b-badge v-else-if="!item.saved" variant="success" class="alert-warning"><i class="fa fa-exclamation-circle"></i> 저장되지 않은 변경사항이 있습니다</b-badge>
        </div>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="item_org.init" variant="outline-danger" @click="removePage()" :disabled="busy.removePage">
          삭제
          <b-spinner class="ml-1" small v-if="busy.removePage"></b-spinner>
        </b-button>
        <b-button v-if="$R('DEV')" variant="outline-light" @click="$modal.show({title: 'JSON 보기', type: 'json', item: item_org})">
          JSON
        </b-button>
        <b-button variant="primary" @click="ok()" :disabled="busy.savePage">
          저장
          <b-spinner class="ml-1" small v-if="busy.savePage"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style scoped>
@import '~handsontable/dist/handsontable.full.css';
</style>
<script>
import {HotTable} from '@handsontable/vue';
import Handsontable from 'handsontable';
import {down} from '@/shared/impexp'

export default {
  name: 'PriceRebateModal',
  props: ['modal', 'cfMap', 'shop', 'shopMap', 'list'],
  components: {HotTable},
  data() {
    return {
      item: {},
      item_org: {},
      addRowNum: 1,
      busy: {goodsInfo: false, savePage: false, removePage: false},
      hotSettings: {
        data: [],
        // data: Handsontable.helper.createSpreadsheetData(6, 10),
        //'최종판매가', '정산판매가', '지정수수료(%)', 파트너 등급제로 삭제
        colHeaders: ['발란코드','지정소비자가', '<span class="text-muted">상품명</span>',
          // '<span class="text-muted">판매가(원본)</span>', 파트너 등급제로 삭제
          '<span class="text-muted">소비자가(원본)</span>',
          '<span class="text-muted">상시 소비자가</span>',
          '<span class="text-muted">발란회원가</span>',
          // '<span class="text-muted">상시 정산가</span>',
        ],
        columns: [
          {data: 'goods_no', type: 'numeric'},
          // 230707 부터 옵션가가 제거됨에 따라 Size 별 설정도 제거
          // {data: 'Size'},
          // {data: 'final_sale_price', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}},
          // {data: 'price', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}},
          {data: 'consumer', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}},
          // {data: 'rebate', type: 'numeric'},
          {data: 'info', renderer: 'html', editor: false},
          // {data: 'goods_price', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}, editor: false},
          {data: 'goods_consumer', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}, editor: false},
          {data: 'local_consumer', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}, editor: false},
          {data: 'local_price', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}, editor: false},
          // {data: 'local_supply', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}, editor: false},
        ],
        cells: (row, col, prop) => {
          let className = {
            goods_no: 'htCenter',
            Size: 'htCenter',
            info: 'htLeft',
            goods_price: 'htRight text-muted',
            goods_consumer: 'htRight text-muted',
            local_consumer: 'htRight text-muted',
            local_price: 'htRight text-muted',
            local_supply: 'htRight text-muted'
          }[prop] || 'htRight';
          return className ? {className} : {};
        },
        colWidths: [90, 100, 380, 100, 100, 100],
        rowHeaders: true,
        height: 500,
        afterChange: async (changes) => {
          let changedGoodsNoRows = changes && changes.filter(([row, prop, oldValue, newValue]) => {
            return prop === 'goods_no' && oldValue + '' !== newValue + '';
          }).map(e => e[0]) || [];
          if (changedGoodsNoRows.length) { // goods_no 자체가 바뀌었다면 info, 가격 등을 갱신해야 한다.
            changedGoodsNoRows.forEach(row => {
              const obj = this.item.goods[row];
              delete obj.info;
              delete obj.goods_consumer;
              delete obj.goods_price;
            });
            await this.goodsInfo();
          }
          this.$refs.ht.hotInstance.render();
          this.pageDiff();
        },
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        licenseKey: 'non-commercial-and-evaluation',
      },
      hasStockRenderer(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        td.style.backgroundColor = 'yellow';
      },
    }
  },
  async created() {

  },
  methods: {
    async showModal(row) {
      if (!row) { // 새 페이지 생성
        row = {
          item: {
            name: '', desc: '', goods: [{}],
            st: this.$utils.kstDHM(this.$moment().add(1, 'day').startOf('hour')),
            ed: this.$utils.kstDHM(this.$moment().add(2, 'day').startOf('hour')),
            spread: 1, init: false, saved: true
          }
        };
      } else {
        row.item.init = true;
        row.item.saved = true;
      }
      this.item_org = row.item;
      this.item = this.$utils.clone(row.item);
      await this.goodsInfo();
      this.modal.detail = true;
      this.renderTable();
    },
    async modalHide(event) {
      if (event.trigger === 'ok') {
        event.preventDefault && event.preventDefault();
        this.savePage();
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        this.pageDiff();
        if (!this.item.saved) {
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
              event.preventDefault && event.preventDefault();
            }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        } else { // 변경사항이 없으면
          // pass
        }
      }
    },
    async goodsInfo() {
      // 상품정보가 없는 상품을 db 에서 가져온다.
      if (this.busy.goodsInfo) return;

      const new_goods_no = this.item.goods.filter(e => e.goods_no && !this.cfMap[e.goods_no]).map(e => e.goods_no);
      if (new_goods_no.length) {
        this.busy.goodsInfo = true;
        let j = await this.$api.postJson('/price/priceRebateGoodsInfo', {goods_no: new_goods_no});
        this.busy.goodsInfo = false;
        if (j) {
          j.list.forEach(e => this.cfMap[e.goods_no] = e);
        }
      }
      for (let g of this.item.goods) {
        if (g.goods_no && !g.info) {
          let cf = this.cfMap[g.goods_no];
          if (cf) {
            Object.assign(g, {
              info: `<a href="/#/shop/${cf.shop_id}" target="_blank" class="badge badge-success mr-1">${cf.shop_id}. ${(this.shopMap[cf.shop_id] || {}).boutique}</a>` +
                `<a href="/#/goods/${cf.goods_no}" target="_blank" class="badge badge-${cf.tot_stock > 0 ? 'primary' : 'secondary'}">${cf.goods_no}</a> ` +
                `${cf.goods_nm} (재고: ${cf.tot_stock})`,
              valid: true,
            }, cf);
            if (cf.price_table) {
              const priceTable = cf.price_table[0];
              g.local_consumer = priceTable.consumer.localPrice;
              g.local_price = priceTable.sale.localPrice;
              g.local_supply = priceTable.supply.localPrice;
            }
          } else {
            Object.assign(g, {
              info: `<span class="text-muted text-danger">상품정보를 등록상품(confirmed)에서 찾지 못했습니다</span>`, valid: false
            });
          }
        }
      }
    },
    renderTable() {
      setTimeout(_ => {
        let instance = this.$refs.ht.hotInstance;
        instance.loadData(this.item.goods);
        instance.render();
        window.ht = instance;
      });
    },
    addRows(n) {
      let instance = this.$refs.ht.hotInstance;
      // let col = instance.countRows();
      // instance.alter('insert_row', col, n);
      for (let i = 0; i < n; i++) {
        this.item.goods.push({});
      }
      instance.loadData(this.item.goods);
      instance.render();
    },
    showRows() {
      this.$modal.show({title: '가격 설정 데이터', item: this.item.goods, type: 'json'});
    },
    removeRows(type) {
      let page = this.item;
      let instance = this.$refs.ht.hotInstance;
      if (type === 'empty') {
        const goodsCols = 'goods_no,consumer'.split(',');
        // const goodsCols = 'goods_no,final_sale_price,price,consumer,rebate'.split(',');
        page.goods = page.goods.filter(e => !goodsCols.every(k => e[k] == null || e[k] === ''));
        this.$alertTop(`빈 행을 정리했습니다`, {variants: 'warning'});
      } else if (type === 'not_done') {
        page.goods = page.goods.filter(e => e.goods_no && (e.consumer) != null);
        // page.goods = page.goods.filter(e => e.goods_no && (e.final_sale_price || e.price || e.consumer || e.rebate) != null);
        this.$alertTop(`완전하지 않은 행을 정리했습니다`, {variants: 'danger'});
      }
      instance.loadData(this.item.goods);
      instance.render();
    },
    pageDiff() {
      let diff = {};
      const cols = 'name,st,ed,spread,desc'.split(',');
      const goodsCols = 'goods_no,consumer'.split(',');
      for (let c of cols) {
        if (this.item[c] !== this.item_org[c]) diff[c] = true;
      }
      let _str = this.item.goods.filter(e => e.goods_no && (e.consumer) != null).sort((a, b) => a.goods_no - b.goods_no).map(e => goodsCols.map(c => e[c]).join('')).join('');
      let _orgstr = this.item_org.goods.filter(e => e.goods_no && (e.consumer) != null).sort((a, b) => a.goods_no - b.goods_no).map(e => goodsCols.map(c => e[c]).join('')).join('');

      // let _str = this.item.goods.filter(e => e.goods_no && (e.final_sale_price || e.price || e.consumer || e.rebate) != null).sort((a, b) => a.goods_no - b.goods_no).map(e => goodsCols.map(c => e[c]).join('')).join('');
      // let _orgstr = this.item_org.goods.filter(e => e.goods_no && (e.final_sale_price || e.price || e.consumer || e.rebate) != null).sort((a, b) => a.goods_no - b.goods_no).map(e => goodsCols.map(c => e[c]).join('')).join('');
      if (_str !== _orgstr) diff.goods = true;

      this.item.saved = !Object.keys(diff).length;
    },
    async savePage() {
      const page = this.item;

      const allowLossRatio = 0.3;
      const alertLossString = allowLossRatio > 0 ? `${allowLossRatio * 100} % 초과로 ` : '';
      let nan = [], low = [], lowSettle = [];
      let goods = page.goods.filter(e => e.valid && e.goods_no && (e.consumer) != null).map(e => {
      // let goods = page.goods.filter(e => e.valid && e.goods_no && (e.final_sale_price || e.price || e.consumer || e.rebate) != null).map(e => {
        let obj = {}, keys = 'goods_no,consumer'.split(',');
        // let obj = {}, keys = 'goods_no,final_sale_price,price,consumer,rebate'.split(',');
        for (let [k, v] of Object.entries(e).filter(([k, v]) => ~keys.indexOf(k))) {
          if (k !== 'Size' && typeof (v) === 'string' && isNaN(v.replace(/,/g, ''))) {
            nan.push(v);
            continue;
          }
          if (typeof (v) === 'string') v = v.trim();
          if (v != null && v !== '') {
            if (k === 'Size') obj[k] = v;
            else obj[k] = (typeof (v) === 'string' ? +v.replace(/,/g, '') : v);
            if (obj[k] < 10000 && 'consumer'.split(',').includes(k)) {
            // if (obj[k] < 10000 && 'final_sale_price,price,consumer'.split(',').includes(k)) {
              low.push(obj.goods_no);
            }
          }
        }
        // if (e.final_sale_price) { // 정산가가 판매가보다 높은 케이스 체크
        //   if (e.price && e.final_sale_price < e.price * (1 - allowLossRatio) ||
        //     !e.price && e.local_supply && e.final_sale_price < e.local_supply * (1 - allowLossRatio)) {
        //     lowSettle.push(e.goods_no);
        //   }
        // }
        // if (e.price && !e.final_sale_price) { // 정산가가 판매가보다 높은 케이스 체크
        //   if (e.local_price && e.price * (1 - allowLossRatio) > e.local_price) {
        //     lowSettle.push(e.goods_no);
        //   }
        // }

        return obj;
      });

      if (nan.length) return alert('다음 항목들이 숫자가 아닙니다\n' + nan.join('\n'));
      if (low.length) return alert('다음 상품들의 가격이 10000원 미만입니다\n' + low.join('\n'));
      if (lowSettle.length) return alert(`다음 상품들의 정산가격이 판매가격보다 ${alertLossString}높습니다\n` + lowSettle.join('\n'));
      if (!goods.length) return alert('유효한 설정 항목이 없습니다');

      const goodsMulti = this.$utils.arr2multi(goods, 'goods_no');
      const dupGoods = Object.values(goodsMulti).filter(e => e.length > 1);
      if (dupGoods.length) return alert(`중복된 발란코드가 존재합니다 : ${dupGoods.map(e => e[0].goods_no).join(', ')}`);

      if (!page.name.trim()) return alert('타이틀을 입력해주세요');
      if (!page.st.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/)) return alert('시작시각을 YYYY-MM-DD HH:mm 형태로 입력해주세요');
      if (this.$moment(page.st).toDate().toString() === 'Invalid Date') return alert('시작시각의 값이 정상적인 시각이 아닙니다.');
      if (!page.ed.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/)) return alert('종료시각을 YYYY-MM-DD HH:mm 형태로 입력해주세요');
      if (this.$moment(page.ed).toDate().toString() === 'Invalid Date') return alert('종료시각의 값이 정상적인 시각이 아닙니다.');
      if (page.ed < page.st) return alert('시작시각이 종료시각보다 미래입니다');

      this.busy.savePage = true;
      let j = await this.$api.postJson('/price/checkPriceRebate', {...page, goods});
      this.busy.savePage = false;
      if (j) {
        // 중복여부 안내
        const dupArrs = Object.values(j.dupMap);
        const goodsMap = this.$utils.arr2map(page.goods, 'goods_no', 'price');
        if (dupArrs.length) return alert(`${dupArrs.length} 건의 상품이 이전 가격설정에 존재합니다.` +
          `\n${dupArrs.map(arr => `${arr[0].goods_no} - 현재 설정 가격: ${goodsMap[arr[0].goods_no]}\n${
            arr.map(e => `  #${e.no} ${e.name}, 소비자가: ${e.consumer}, 종료: ${e.ed}`).join('\n')
            // arr.map(e => `  #${e.no} ${e.name}, 판매가격: ${e.price}, 정산가격: ${e.final_sale_price}, 종료: ${e.ed}`).join('\n')
          }`).join('\n')}` +
          `\n일자가 겹치지 않게 조절하거나 기존 설정을 제거후 다시 시도해주세요`);

        // goods_no 와 price 나 consumer 나 rebate 가 있어야 최소조건이다.
        if (goods.length !== page.goods.length && !confirm(`${page.goods.length - goods.length} 개의 유효하지 않은 행이 존재합니다. 제외하고 저장하시겠습니까?`)) return;

        const shop_ids = this.$utils.set(goods.map(e => this.cfMap[e.goods_no].shop_id));
        delete page.shop_id;

        this.busy.savePage = true;
        j = await this.$api.postJson('/price/savePriceRebate', {...page, shop_ids, goods});
        this.busy.savePage = false;
        if (j) {
          this.$alertTop('저장되었습니다');
          this.modal.detail = false;
          this.list();
        }
      }
    },
    async removePage() {
      const page = this.item;
      if (!confirm(`[${page.name}] 페이지를 삭제하시겠습니까?`)) return;
      if (page.init === false) {
        this.$alertTop('삭제되었습니다');
        this.modal.detail = false;
        return;
      }
      this.busy.removePage = true;
      let j = await this.$api.postJson('/price/removePriceRebate', {no: page.no});
      this.busy.removePage = false;
      if (j) {
        this.$alertTop('삭제되었습니다');
        // parent의 items 에서 제거한다.
        this.modal.detail = false;
        this.list();
      }
    },
    async down() {
      const fields = 'goods_no,consumer,shop_id,goods_nm,tot_stock,goods_consumer,goods_price'.split(',');
      down(this.item.goods, fields, fields, `병행가격설정_${this.$utils.kstDT()}`, 'xlsx');
    },
  }
}
</script>
