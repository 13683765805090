<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>병행상품 가격 및 수수료 설정 </strong>
      </div>

      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="No, 타이틀, 시각, 비고, shop_id, goods_no, Size, 가격" v-model="form.list.search" @keypress.enter.prevent.stop="list"
                      v-focus></b-form-input>
      </b-input-group>

      <shop-preset v-model="form.list.shop"></shop-preset>

      <b-row class="my-2">
        <b-col>
          <div><small class="mb-n2">상태</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.list.status" :options="[
            {text: '전체', value: 'ALL'},
            {text: '진행중', value: 'ongoing'},
            {text: '예정', value: 'tobe'},
            {text: '종료', value: 'done'},
          ]"></b-form-radio-group>
        </b-col>
        <b-col>
          <div><small class="mb-n2">정렬순서</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.list.orderby" :options="[
            {text: '최신순', value: 'no'},
            {text: '시작시각', value: 'st'},
            {text: '종료시각', value: 'ed'},
            {text: '최종수정시각', value: '_dt'},
          ]"></b-form-radio-group>
        </b-col>
      </b-row>

      <b-button v-if="$R('ADMIN')" variant="outline-danger" class="pull-right ml-2" @click="renewPriceRebateAll">전체갱신</b-button>
      <b-button variant="outline-info" class="pull-right" @click="modal.help = true"><i class="fa fa-question"></i></b-button>

      <b-button variant="primary" class="mr-1" @click="list" :disabled="busy.list">검색
        <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
      </b-button>
      <b-button variant="success" class="mr-1" @click="newPage">새 페이지 생성</b-button>
      <b-button v-if="dupArr.length" variant="danger" class="mr-1" v-b-modal.dupGoodsModal>가격설정 중복 상품({{ dupArr.length }} 개)</b-button>
    </b-card>

    <c-table :table-data="items.list" :fields="fields.list" :perPage.sync="perPage.list" :isBusy="busy.list" :getMoreBusy="busy.listmore"
             :hasMore="hasMore.list"
             :caption="items.list.length + ' 개'" @btn-clicked="btnAction" @get-more="list(true)"></c-table>

    <price-rebate-modal ref="modal" v-bind="{modal, cfMap, shop, shopMap, list}"></price-rebate-modal>

    <price-rebate-help ref="help" :value.sync="modal.help"></price-rebate-help>

    <b-modal id="dupGoodsModal" v-model="modal.dupGoods" title="가격설정 중복 상품" size="xl">
      <div v-for="arr in dupArr" :key="arr[0].goods_no">
        <a :href="`/#/shop/${arr[0].shop_id}`" class="badge badge-success mr-1" target="_blank">{{ arr[0].shop_id }}. {{ arr[0].shop }}</a>
        <a :href="`/#/goods/${arr[0].goods_no}`" class="badge badge-primary mr-1" target="_blank">{{ arr[0].goods_no }}</a>
        <b-badge class="mr-1" :variant="{registered: 'success', terminated: 'danger'}[arr[0].goods_status]">{{
            {
              registered: '등록됨',
              terminated: '종료됨'
            }[arr[0].goods_status]
          }}
        </b-badge>
        <b-badge class="mr-1" :variant="{normal: 'success', runout: 'warning'}[arr[0].stock_status]">{{
            {
              normal: '재고있음',
              runout: '품절'
            }[arr[0].stock_status]
          }}
        </b-badge>
        <b-badge class="mr-1" :variant="{view: 'success', notview: 'warning'}[arr[0].display_status]">{{
            {
              view: '노출',
              notview: '미노출'
            }[arr[0].display_status]
          }}
        </b-badge>
        {{ arr[0].goods_nm }}
        <div v-for="e in arr" class="pl-3" :key="e.no">
          #{{ e.no }}
          <b-badge variant="secondary">타이틀</b-badge>
          {{ e.name }}
          <b-badge variant="secondary">기간</b-badge>
          {{ e.st }} ~ {{ e.ed }},
          <span v-for="[k, v] in [['최종판매가', e.final_sale_price], ['정산판매가', e.price], ['지정소비자가', e.consumer], ['지정수수료', e.rebate]].filter(e=>e[1])">
            <b-badge variant="light">{{ k }}</b-badge> {{ v }}
          </span>
        </div>
      </div>
    </b-modal>
    <!--<b-alert show variant="warning">롯데아이몰에 등록된 상품은 별도로 가격변경을 직접 해야 합니다(가격변경 승인제)</b-alert>-->
  </div>
</template>

<style scoped>
</style>

<script>
import priceRebateModal from '@/views/price/PriceRebateModal.vue'
import priceRebateHelp from '@/views/price/PriceRebateHelp.vue'

export default {
  name: 'PriceRebate',
  title: '병행가격설정',
  components: {priceRebateModal, priceRebateHelp},
  data() {
    return {
      defaultForm: {
        list: {
          search: '',
          shop: [],
          status: 'ALL',
          orderby: 'no',
          limit: 500,
          skip: 0,
        },
      },
      form: {list: {}},
      lastBody: {list: {}},
      items: {list: []},
      perPage: {list: 50},
      busy: {list: false, listmore: false, goodsInfo: false},
      hasMore: {list: false},
      modal: {detail: false, dupGoods: false, help: false},
      ac: {list: null}, // abortController
      fields: {
        list: [
          'selected',
          // {key: 'html1', label: 'Shop ID', class: 'text-center'},
          {key: 'no', label: 'No', class: 'text-center'},
          {key: 'html5', label: '상태', class: 'text-center'},
          {key: 'html7', label: '타이틀'},
          // {key: 'st', label: '시작시각', class: 'text-center', style: {maxWidth: '100px'}},
          // {key: 'ed', label: '종료시각', class: 'text-center', style: {maxWidth: '100px'}},
          {key: 'html6', label: '적용기간', class: 'text-center'},
          {key: 'html2', label: '적용대상', class: 'text-center', style: {maxWidth: '120px'}},
          {key: 'html3', label: '상품수', class: 'text-center', style: {maxWidth: '100px'}},
          {key: 'html4', label: '생성시각', class: 'text-center', style: {maxWidth: '140px'}},
          {key: 'desc', label: '비고'},
          {key: '_actions', label: '상세', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
        ],
      },
      labelMap: {all: '전체', ongoing: '진행중', tobe: '예정', done: '종료'},
      variantMap: {all: 'light', ongoing: 'success', tobe: 'warning', done: 'secondary'},

      shop: [],
      shopMap: {},
      cfMap: {},
      dupArr: [],
    }
  },
  async created() {
    this.form.list = this.$utils.clone(this.defaultForm.list);
    await this.$api.setMeta(this, 'shop');

    // const shop_id = this.$route.params.shop_id;
    // if (shop_id) {
    //   this.selectedShop = this.shop.filter(e=>e.shop_id === +shop_id)[0];
    // }

    // query string 으로 받은 내용을 폼에 적용한다.
    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k => {
        if (this.$route.query[k] != null) {
          const v = this.$route.query[k];
          if ('limit,skip'.split(',').includes(k)) {
            this.form.list[k] = +v;
          } else if (k === '$shop') {
            this.form.list.shop = v.split(',').map(e => this.shopMap[e]);
          } else if (Object.keys(this.form.list).includes(k)) {
            this.form.list[k] = v;
          }
        }
      });
    }

    await this.list();
    this.checkDupGoods();
    this.checkHelpAgreed();
  },
  methods: {
    /**
     * 전체 설정을 확인하여 중복인 설정을 상품별로 반환한다.
     */
    async checkDupGoods() {
      let j = await this.$api.postJson('/price/checkDupGoods');
      if (j) {
        this.dupArr = j.dupArr;
      }
    },
    async checkHelpAgreed() {
      let j = await this.$api.getJson('/price/checkHelpAgreed');
      if (j && j.user && !j.user.agreed) {
        this.modal.help = true;
      }
    },
    async list(more) {
      let form = this.form.list;

      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      let j = await this.$api.postTable(this, '/price/priceRebate', {...form, shop}, {more, fnAssign: this.assignTableData});
      // await this.$api.postTable(this, '/price/priceRebate', body, {fnAfter:j=>{
      //   Object.entries(this.$utils.arr2multi(j.list, 'shop_id')).forEach(([k,v])=>{
      //     this.shopMap[k].pages = v;
      //     let done = 0, ongoing = 0, tobe = 0, dhm = this.$utils.kstDHM();
      //     v.forEach(p=>{
      //       p.init = true;
      //       p.saved = true;
      //       if (p.ed <= dhm) done++;
      //       else if (p.st >= dhm) tobe++;
      //       else ongoing++;
      //       this.pageMap[`${p.shop_id}|${p.page}`] = this.$utils.clone(p);
      //     });
      //     Object.assign(this.shopMap[k], {done, ongoing, tobe, page_cnt:v.length});
      //   });
      //   this.selectShop(this.selectedShop || 'ongoing');
      //   this.goodsInfo();
      //   this.pageStat();
      //   this.$forceUpdate();
      // }});
    },
    assignTableData(e) {
      const dhm = this.$utils.kstDHM();
      const $m = this.$moment;
      const durationStr = $m().add($m.duration($m(e.ed, 'YYYY-MM-DD HH:mm:ss').diff($m(e.st, 'YYYY-MM-DD HH:mm:ss'))).asHours(), 'hour').fromNow().replace('이내', '동안');
      e.status = e.ed <= dhm ? 'done' : e.st >= dhm ? 'tobe' : 'ongoing';

      // shop 갯수 표현
      if (e.shop_id) {
        e.shop_name = (this.shopMap[e.shop_id] || {}).boutique || '';
        e.shop_badges = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success mr-1">${e.shop_id}. ${e.shop_name}</a>`;
      } else if (e.shop_ids) {
        if (e.shop_ids.length <= 2) {
          e.shop_badges = e.shop_ids.map(shop_id => `<a href="/#/shop/${shop_id}" target="_blank" class="badge badge-success mr-1">${shop_id}. ${(this.shopMap[shop_id] || {}).boutique || ''}</a>`).join('');
        } else {
          e.shop_badges = e.shop_ids.slice(0, 5).map(shop_id => `<a href="/#/shop/${shop_id}" target="_blank" class="badge badge-success mr-1">${shop_id}</a>`).join('');
          if (e.shop_ids.length > 5) {
            e.shop_badges += `<span class="badge badge-success mr-1">외 ${e.shop_ids.length - 5} 개 SHOP</span>`;
          }
        }
      }

      const edStr = e.status === 'ongoing' ? $m(e.ed).fromNow().replace('이내', '뒤 종료') :
        e.status === 'done' ? $m(e.ed).fromNow().replace('전', '전 종료') :
          $m(e.st).fromNow().replace('이내', '뒤 시작');

      e.html1 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop_name}</a>`;
      e.html2 = {0: 'HUB DB 에만', 1: '발란몰까지', 2: '외부몰까지(전부)'}[e.spread];
      e.html3 = e.goods.length;
      e.html4 = `${e._dt}<br/><span class="text-muted">(${this.$moment(e._cdt).fromNow()}${e._name ? ', ' + e._name : ''})</span>`;
      e.html5 = `<span class="badge badge-${this.variantMap[e.status]}">${this.labelMap[e.status]}</span>`;
      e.html6 = `${e.st} ~ ${e.ed}<br/><span class="text-muted">(${durationStr}, ${edStr})</span>`;
      e.html7 = `${e.shop_badges ? e.shop_badges + '<br/>' : ''}${e.name}`;
    },
    btnAction(row, event) {
      if (event === 'show_modal') {
        this.$refs.modal.showModal(row);
      }
    },
    newPage() {
      this.$refs.modal.showModal();
    },
    async renewPriceRebateAll() {
      let j = await this.$api.getJson('/price/renewPriceRebateAll');
      if (j) {
        alert('시작되었습니다.');
      }
    }
  }
}
</script>
