<template>
  <div>
    <b-modal title="가격설정 안내" size="xl" v-model="v" ok-title="네, 이해했습니다" cancel-title="우선 살펴보겠습니다" @ok="setHelpAgreed">
      <h4>병행가격설정이 최종판매가, 정산판매가를 별도로 지정하는 방식으로 변경됩니다.</h4>
      <h4>아래의 예시를 참고해주세요.</h4>
      <hr/>

      <h5>122만원 상품 예시 - 판매가 122 만원, 정산기준가 122만원</h5>
      <div>
        발란몰에는 쿠폰 적용후 가격이 122만원이 되도록 계산됩니다.
      </div>
      <div>
        파트너에게는 정산기준가 * 적용수수료율 기준으로 수수료를 제하고 정산합니다.
      </div>
      <div>
        * 정산기준가 : [정산판매가] or [SHOP 설정에 따라 결정된 가격테이블의 판매기준가(standardPrice)]
      </div>
      <div>
        * 적용수수료율 : [지정수수료(%)] or [SHOP 설정에 따른 수수료율]
      </div>
      <img :src="`/img/priceRebateHelp/pr_01.png`" style="max-width: 100%" />
      <hr/>

      <h5>1. 정산판매가 조절시 - 판매가 122 만원 유지, 정산기준가 110만원</h5>
      <img :src="`/img/priceRebateHelp/pr_02.png`" style="max-width: 100%" />
      <hr/>

      <h5>2. 최종판매가, 정산판매가 조절시 - 판매가 115 만원, 정산기준가 110만원</h5>
      <img :src="`/img/priceRebateHelp/pr_03.png`" style="max-width: 100%" />
      <hr/>

      <h5>3. 최종판매가만 조절시 - 판매가 115 만원, 정산기준가 122만원</h5>
      <img :src="`/img/priceRebateHelp/pr_04.png`" style="max-width: 100%" />
    </b-modal>
  </div>
</template>
<style scoped>
</style>
<script>

export default {
  name: 'PriceRebateHelp',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Boolean,
  },
  data() {
    return {

    }
  },
  computed: {
    v: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('update:value', v);
      }
    }
  },
  async created() {
  },
  methods: {
    async setHelpAgreed() {
      let j = await this.$api.getJson('/price/setHelpAgreed');
      if (j && j.ok) {
        this.$alertTop('확인되었습니다');
      }
    }
  }
}
</script>
